import { Widgets } from "@mui/icons-material";
import { Nav } from "../components/Nav";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { useUser } from "../context/user";
import { Lockout } from "../components/Lockout";
import { MobileLockout } from "../components/MobileLockout";
import { LoginButton } from "../components/Buttons/LoginButton";
import { UnauthorizedError } from "../components/errors/UnathorizedError";
import OnboardingView from "./OnboardingViewRevamp";
import { BorderBox } from "../components/layout/BorderBox";
import LoginView from "./LoginView";
import { useUIState } from "../context/uiState";
import OnboardingCompleted from "../components/OnboardingCompleted";
import { useNavigate, useLocation } from "react-router-dom";
import LayoutWrapper from "../components/layout/UILayout";

const EXCEPTED_ROUTES = ["/record", "/user"];

const isExceptedRoute = (pathname: string) => {
  return EXCEPTED_ROUTES.some((route) => pathname.includes(route));
};

export const RootView = ({ children }: { children: any }) => {
  const { userState, lockedOut, unauthorized, awaitingSignup, awaitingLogin } =
    useUser();
  const { state } = useUIState();
  const { platform } = state;
  const isFullScreenComponent =
    [
      "/",
      "/note/",
      "/templates",
      "/demo",
      "/patient",
      "/notes",
      "/group",
      "/patients",
      "/onboarding",
      "/dashboard",
      "/record",
      "/group-invite",
    ].some((path) => window.location.pathname.startsWith(path)) || false;
  const userEmail = userState?.userInfo?.email || "";
  const paymentStatus = userState?.paymentStatus || "";
  const location = useLocation();
  const isExcepted = isExceptedRoute(location.pathname);
  return (
    <Box
      id="root-container"
      sx={{
        maxWidth: "100vw",
        overflowX: "scroll",
        backgroundColor: awaitingSignup
          ? "rgba(213, 211, 224, 0.3)"
          : "transparent",
        minHeight: "100vh",
      }}
    >
      <Nav />
      {userState === undefined && unauthorized !== true ? (
        <Box
          id="test"
          sx={{
            display: "flex", // Enable flexbox
            width: "100%",
            height: "80vh", // Set the desired height
            alignItems: "center", // Vertically center
            justifyContent: "center", // Horizontally center
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            paddingTop: "70px",
            display: "flex",
            justifyContent: "center",
            overflowX: "hidden", // Prevent horizontal scroll
          }}
          id="root-main"
        >
          <Box
            sx={{
              width: {
                xs: isFullScreenComponent ? "100%" : "95%",
                md: isFullScreenComponent ? "100%" : "80%",
              },
            }}
          >
            {lockedOut && !isExcepted && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "70%" }}>
                  <Lockout
                    user_email={userEmail}
                    payment_status={paymentStatus}
                  />
                </Box>
              </Box>
            )}
            {unauthorized && (
              <UnauthorizedError message="Your account credentials have expired. Please log in again." />
            )}
            {awaitingLogin && <LoginView />}
            {awaitingSignup && <OnboardingView />}
            {/* {!lockedOut &&
            !awaitingSignup &&
            !awaitingLogin &&
            !unauthorized &&
            platform !== "desktop" && <OnboardingCompleted onNext={() => {}} />} */}
            {(!lockedOut || isExcepted) &&
              !awaitingSignup &&
              !awaitingLogin &&
              !unauthorized &&
              children}
          </Box>
        </Box>
      )}
    </Box>
  );
};
