import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Modal,
  Card,
  CardContent,
} from "@mui/material";
import { Patient, NoteDataType, ProviderBillingInfo } from "../types/types";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { formatDate, formatDuration } from "../utils/utils";
import { RoundedButton } from "../styles/CustomButtons";
import ReactConfetti from "react-confetti";
import { CustomTransitionModal } from "../components/Modals/CustomModal";

interface BillingSubmissionViewProps {
  patient?: Patient | null;
  providerBillingInfo?: ProviderBillingInfo;
  note?: NoteDataType;
  patientId?: string;
  providerId?: string;
  noteId?: string;
  billingCodeInfo: Record<string, string>;
}

const BillingSubmissionView: React.FC<BillingSubmissionViewProps> = ({
  patient: initialPatient,
  note: initialNote,
  providerBillingInfo,
  patientId,
  noteId,
  billingCodeInfo,
}) => {
  const [patient, setPatient] = useState<Patient | null | undefined>(
    initialPatient || null
  );
  const [note, setNote] = useState<NoteDataType | null>(initialNote || null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [eSignatureChecked, setESignatureChecked] = useState<boolean>(false);
  const [isConfettiActive, setIsConfettiActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { getAccessToken } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const accessToken = await getAccessToken();

      try {
        if (patientId && !patient) {
          const patientResponse = await APIService.makeAPIGetRequest({
            requestString: `/patient/get?patient_id=${patientId}`,
            accessToken,
          });
          if (patientResponse.ok) {
            setPatient(patientResponse.value.patient);
          }
        }

        if (noteId && !note) {
          const noteResponse = await APIService.makeAPIGetRequest({
            requestString: `/notes/${noteId}`,
            accessToken,
          });
          if (noteResponse.ok) {
            setNote(noteResponse.value);
          }
        }
      } catch (err) {
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [patientId, noteId, patient, note, getAccessToken]);

  const handleSubmitBilling = async () => {
    console.log("Submitting billing...");
    // Implement billing submission logic here

    // Open the modal
    setIsModalOpen(true);

    // Activate confetti
    setIsConfettiActive(true);

    // Deactivate confetti after 5 seconds
    setTimeout(() => setIsConfettiActive(false), 8000);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const InfoBox: React.FC<{ title: string; data: Record<string, any> }> = ({
    title,
    data,
  }) => (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(data).map(([key, value]) => (
          <Grid item xs={6} key={key}>
            <Typography variant="subtitle2">
              {key.replace(/_/g, " ").toUpperCase()}
            </Typography>
            <Typography>{value || "N/A"}</Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );

  const CPTCodeBox: React.FC<{ code: string; justification: string }> = ({
    code,
    justification,
  }) => (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        CPT Code
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h3" fontWeight="bold">
          {code || "N/A"}
        </Typography>
      </Box>
      <Typography variant="body2">
        {justification || "No justification provided."}
      </Typography>
    </Paper>
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const getWindowDimensions = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {isConfettiActive && (
        <>
          <ReactConfetti
            width={getWindowDimensions().width}
            height={getWindowDimensions().height}
            recycle={false}
            numberOfPieces={300}
            colors={["#85bb65", "#4CAF50", "#2E7D32", "#1B5E20"]}
            drawShape={(ctx) => {
              ctx.fillStyle = ctx.strokeStyle;
              ctx.beginPath();
              ctx.rect(-15, -7, 30, 14);
              ctx.fill();
              ctx.stroke();
              ctx.closePath();
            }}
            gravity={0.08}
            wind={0.01}
          />
          <ReactConfetti
            width={getWindowDimensions().width}
            height={getWindowDimensions().height}
            recycle={false}
            numberOfPieces={200}
            colors={["#FFD700", "#FFA500", "#C0C0C0", "#CD7F32"]}
            gravity={0.2}
            drawShape={(ctx) => {
              ctx.fillStyle = ctx.strokeStyle;
              ctx.beginPath();
              ctx.arc(0, 0, 8, 0, Math.PI * 2);
              ctx.fill();
              ctx.stroke();
              ctx.closePath();
            }}
          />
        </>
      )}
      <Box sx={{ flexGrow: 1, overflowY: "auto", pb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Billing Submission
        </Typography>

        {billingCodeInfo && (
          <CPTCodeBox
            code={billingCodeInfo.top_code}
            justification={billingCodeInfo.justification}
          />
        )}

        {note && (
          <InfoBox
            title="Session Information"
            data={{
              "Note Title": note.note_title,
              "Session Date": formatDate(note.time_uploaded, "datelong"),
              "Session Time": note.transcription_start_timestamp
                ? `${formatDate(
                    note.transcription_start_timestamp,
                    "time"
                  )} – ${formatDate(note.transcription_end_timestamp, "time")}`
                : formatDate(note.time_uploaded, "time"),
              "Session Duration": note.transcription_length_seconds
                ? formatDuration(note.transcription_length_seconds)
                : "N/A",
            }}
          />
        )}

        {patient && (
          <InfoBox
            title="Patient Information"
            data={{
              "First Name": patient.first_name,
              "Last Name": patient.last_name,
              Identifier: patient.identifier,
              "Date of Birth": patient.dob,
              Address: patient.address,
              Phone: patient.phone,
              Gender: patient.gender,
            }}
          />
        )}

        {providerBillingInfo && (
          <InfoBox
            title="Provider Information"
            data={{
              "First Name": providerBillingInfo.first_name,
              "Last Name": providerBillingInfo.last_name,
              "NPI Number": providerBillingInfo.npi_number,
              "Place of Service": providerBillingInfo.place_of_service,
              "Facility Address": providerBillingInfo.facility_address,
              State: providerBillingInfo.state,
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          pt: 2,
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={eSignatureChecked}
              onChange={(e) => setESignatureChecked(e.target.checked)}
            />
          }
          label={
            <Typography variant="body2">
              I hereby certify that the information provided is true and
              accurate to the best of my knowledge.
            </Typography>
          }
        />
        <RoundedButton
          variant="contained"
          sx={{
            fontSize: "0.85rem",
            fontWeight: "700",
            whiteSpace: "nowrap",
            px: 2,
          }}
          color="primary"
          onClick={handleSubmitBilling}
          disabled={!eSignatureChecked}
        >
          Submit
        </RoundedButton>
      </Box>

      <CustomTransitionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="billing-submission-modal"
        aria-describedby="billing-submission-result"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "95%", sm: "80%" },
            maxWidth: 600,
            maxHeight: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            <Typography
              id="billing-submission-modal"
              variant="h5"
              component="div"
              gutterBottom
            >
              Thanks for testing!
            </Typography>
            <Typography
              id="billing-submission-result"
              variant="body1"
              sx={{ mt: 2 }}
            >
              We're still testing this feature, and your bill wasn't actually
              submitted. 😢
            </Typography>
            <Typography
              id="billing-submission-result"
              variant="body1"
              sx={{ mt: 2 }}
            >
              But we would love your feedback on what you'd like to see in this
              feature. Please fill out this short survey.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <RoundedButton
                variant="contained"
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/1RzZun-PoatIeU3B_fXI0MCTFMB8_nwA0jfBN65Z8Bj4/edit",
                    "_blank"
                  );
                }}
              >
                Provide Feedback
              </RoundedButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button variant="contained" onClick={handleCloseModal}>
                Close
              </Button>
            </Box>
          </CardContent>
        </Card>
      </CustomTransitionModal>
    </Box>
  );
};

export default BillingSubmissionView;
