import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { ProviderBillingInfo } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface ProviderInformationViewProps {
  highlightedFields?: string[];
  onProviderSave?: () => void;
}

const ProviderInformationView: React.FC<ProviderInformationViewProps> = ({
  highlightedFields = [],
  onProviderSave,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [providerInfo, setProviderInfo] = useState<ProviderBillingInfo>();
  const { getAccessToken } = useUser();

  useEffect(() => {
    fetchProviderInfo();
  }, []);

  const fetchProviderInfo = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/user/getProviderBillingInfo`,
        accessToken,
      });

      if (response.ok) {
        const data = response.value.provider_billing_info || {};
        setProviderInfo(data);
      } else {
        throw new Error("Failed to fetch provider information");
      }
    } catch (error) {
      console.error("Error fetching provider information:", error);
      setSnackbar({
        open: true,
        message: "Failed to load provider data",
        severity: "error",
      });
      // Set an empty object as providerInfo to display the form with empty fields
      setProviderInfo(undefined);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setProviderInfo((prev) => {
      if (!prev) return undefined;
      return { ...prev, [name]: value };
    });
    setHasChanges(true);
  };

  const handleSave = async () => {
    if (!providerInfo) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/updateProviderBillingInfo",
        accessToken,
        body: {
          updated_fields: providerInfo,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Provider information updated successfully",
          severity: "success",
        });
        setHasChanges(false);
        if (onProviderSave) onProviderSave();
      } else {
        throw new Error("Failed to update provider information");
      }
    } catch (error) {
      console.error("Error updating provider information:", error);
      setSnackbar({
        open: true,
        message: "Failed to update provider information",
        severity: "error",
      });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!providerInfo) {
    return <Typography>No provider data available.</Typography>;
  }

  const fields = [
    { name: "first_name", label: "First Name" },
    { name: "last_name", label: "Last Name" },
    { name: "npi_number", label: "NPI Number" },
    { name: "place_of_service", label: "Place of Service" },
    { name: "facility_address", label: "Facility Address" },
    { name: "state", label: "State" },
  ];

  console.log(highlightedFields);

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Provider Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {fields.map((field) => (
          <TextField
            key={field.name}
            label={field.label}
            name={field.name}
            value={providerInfo[field.name as keyof ProviderBillingInfo] || ""}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{
              flexGrow: 1,
              minWidth: "150px",
              ...(highlightedFields.includes(field.name) && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "primary.main",
                    borderWidth: 2,
                  },
                },
              }),
            }}
          />
        ))}
      </Box>
      {hasChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ProviderInformationView;
