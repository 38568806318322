import React from "react";
import { Box, TextField, Typography, Paper, useTheme } from "@mui/material";
import { Patient } from "../../types/types";

interface PatientInformationBlockProps {
  patient: Patient;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PatientInformationBlock: React.FC<PatientInformationBlockProps> = ({
  patient,
  onInputChange,
}) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        mb: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Patient Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        <TextField
          label="First Name"
          name="first_name"
          value={patient.first_name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Middle Name"
          name="middle_name"
          value={patient.middle_name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Last Name"
          name="last_name"
          value={patient.last_name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Identifier (PRN)"
          name="identifier"
          value={patient.identifier || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Date of Birth"
          name="dob"
          type="date"
          value={patient.dob || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            max: new Date().toISOString().split("T")[0], // Prevents future dates
          }}
          sx={{
            flexGrow: 1,
            minWidth: "150px",
            "& input::-webkit-calendar-picker-indicator": {
              cursor: "pointer",
            },
          }}
        />
        <TextField
          label="Gender"
          name="gender"
          value={patient.gender || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "100px" }}
        />
        <TextField
          label="Race"
          name="race"
          value={patient.race || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "100px" }}
        />
        <TextField
          label="Address"
          name="address"
          value={patient.address || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 2, minWidth: "200px" }}
        />
        <TextField
          label="Phone Number"
          name="phone"
          value={patient.phone || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Email"
          name="email"
          value={patient.email || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "200px" }}
        />
      </Box>
    </Paper>
  );
};

export default PatientInformationBlock;
