import { Ref, RefObject, useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DebugContext } from "../main";
import { renderDiffs, stripTags, textToParagraph } from "../utils/utils";
import { SVGIcon } from "./SVGIcon";
import { PrimaryButton } from "./Buttons/Buttons";
import { RoundedButton } from "../styles/CustomButtons";
import ThumbUpIconAlt from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownIconAlt from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIconFilled from "@mui/icons-material/ThumbUp";
import ThumbDownIconFilled from "@mui/icons-material/ThumbDown";
import { InlineCommentBox } from "./InlineCommentBox";
import EditDiffComponent from "./Admin/EditDiffComponent";
import { Colors } from "../styles/styles";
import { ConditionalTooltip } from "./ConditionalTooltip";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import EditWithAI from "./Note/EditWithAI";
import { ContentPasteGo, MoreHoriz } from "@mui/icons-material";
import { useDeleteModal } from "../context/modals/DeleteModalContext";
import { useMediaQuery } from "@mui/material";
import { clarity } from "../services/ClarityService";
import { useUIState } from "../context/uiState";

export const NoteSection = ({
  showTooltips = false,
  heading,
  text,
  sectionId,
  moduleId,
  isEdited,
  isRated,
  isAdded,
  isErrored,
  handleAdd,
  handleSaveEdits,
  handleRevertEdits,
  handleRegenerateSection,
  handleRequestAIEdit,
  handleSectionRatingFeedback,
  handleSectionCommentFeedback,
  handleSectionSetCopyStatus,
  handleSectionSendCopyEvents,
  handleStartModuleCustomizationSession,
  context,
  forceDebug,
  isSaving,
  edits,
  onWordClick,
  isEditable = true,
  setTutorialText,
  showChromeExtensionTooltips = false,
}: {
  showTooltips?: boolean;
  heading: string;
  text: string;
  sectionId?: string;
  moduleId?: string;
  isEdited: boolean;
  isRated?: boolean;
  isAdded: boolean;
  isErrored?: boolean;
  handleAdd: () => void;
  handleSaveEdits?: (editedText: string) => void;
  handleRevertEdits?: () => void;
  handleRegenerateSection?: (
    moduleId: string,
    setAwaitingRegenerationTaskId: (taskId: string) => void
  ) => void;
  handleRequestAIEdit?: (
    sectionText: string,
    editInstructions: string,
    moduleId: string,
    setEditTaskResult: (taskId: string) => void
  ) => void;
  handleSectionRatingFeedback?: (rating: number) => void;
  handleSectionCommentFeedback?: (
    comment: string,
    ai_instruction_session_id: string
  ) => void;
  handleSectionSetCopyStatus?: () => void;
  handleSectionSendCopyEvents?: (module_id: string) => void;
  handleStartModuleCustomizationSession?: () => void;
  context?: string;
  forceDebug?: boolean;
  isSaving: boolean;
  edits?: any;
  onWordClick?: (word: string) => void;
  isEditable?: boolean;
  setTutorialText?: (headerText: string, description: string) => void;
  showChromeExtensionTooltips?: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const debug = forceDebug || useContext(DebugContext);

  const { getAccessToken, userState } = useUser();
  const { state: uistate } = useUIState();
  const { loadedInChromeExtension } = uistate;
  const { showDeleteModal: showRevertModal, hideDeleteModal: hideRevertModal } =
    useDeleteModal();

  const [content, setContent] = useState(context);
  const [showContext, setShowContext] = useState<boolean>(false);
  const [showEdits, setShowEdits] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editedText, setEditedText] = useState<string>(text);
  const [showRevert, setShowRevert] = useState<boolean>(isEdited);
  const [lastSavedText, setLastSavedText] = useState<string>(text);
  const [isNA, setIsNA] = useState<boolean>(
    editedText.startsWith("N/A") ? true : false
  );
  const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
  const [markedRated, setMarkedRated] = useState<boolean | "0" | "1">(
    isRated ? true : false
  );
  const [commentSubmitted, setCommentSubmitted] = useState<boolean>(false);
  const [hideCommentBox, setHideCommentBox] = useState<boolean>(false);

  const [copied, setCopied] = useState(false);

  const [editInstructions, setEditInstructions] = useState<string>("");
  const [awaitingEditTaskId, setAwaitingEditTaskId] = useState<string>();
  const [editTaskResult, setEditTaskResult] = useState<{
    result_text: string;
    diffs: any;
  }>();
  const [awaitingRegenerationTaskId, setAwaitingRegenerationTaskId] =
    useState<string>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const componentIsMounted = useRef(false);
  const noteTextRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const editModeRef = useRef<HTMLDivElement | null>(null);
  const editWithAIRef = useRef<HTMLDivElement | null>(null);
  const pollingIntervalId = useRef<number | null>(null);
  const regenerationIntervalRef = useRef<number | null>(null);
  const { troubleWords } = useUser();

  // admin-only, context editing feature
  const [editedContext, setEditedContext] = useState<string | undefined>(
    context
  );

  const [isContextEditMode, setIsContextEditMode] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(text === "");

  const [showAIEditBox, setShowAIEditBox] = useState(false);
  const [showAIEditTrainingTooltip, setShowAIEditTrainingTooltip] =
    useState(false);

  const handleContextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setEditedContext(event.target.value);
  };

  const toggleContextEditMode = () => {
    setIsContextEditMode((prev) => !prev);
  };

  // const handleRegenerateWithEditedContext = () => {
  //   setIsRegenerating(true);
  //   handleRegenerateSection && handleRegenerateSection(editedContext);
  // };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (setTutorialText) {
      setTutorialText(
        "Make an edit",
        "The note section is now a text editor. Want to edit faster? Just type what you want to do into the AI edit bar beneath the note section. If you write your instruction with phrases like 'always include' or 'never', JotPsych will learn your preferences for next time. Give it a try!"
      );
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedText(event.target.value);
  };

  // Split the text by newlines and map each non-empty line to a <p> element
  const paragraphs = editedText.split("\n").map((line, i) => {
    if (line) {
      return textToParagraph({ text: line, className: "py-2" });
    }
  });

  // Handles blur event on the textarea
  const handleBlur = (event: FocusEvent) => {
    // Check if the new focused element is outside the editModeRef container
    // and not within the EditWithAI component
    setTimeout(() => {
      if (
        editModeRef.current &&
        !editModeRef.current.contains(event.relatedTarget as Node) &&
        editWithAIRef.current &&
        !editWithAIRef.current.contains(event.relatedTarget as Node)
      ) {
        setIsEditMode(false);
      }
    }, 10); // A short delay
  };

  //Handles blur event on the textarea
  // const handleBlur = (event: FocusEvent) => {
  //   // Check if the new focused element is outside the editModeRef container
  //   setTimeout(() => {
  //     if (
  //       editModeRef.current &&
  //       !editModeRef.current.contains(event.relatedTarget as Node)
  //     ) {
  //       setIsEditMode(false);
  //     }
  //   }, 10); // A short delay
  // };

  const handleCopy = () => {
    navigator.clipboard.writeText(stripTags(text));
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // set copied back to false after 2 seconds

    if (handleSectionSetCopyStatus) {
      handleSectionSetCopyStatus();
    }
    if (handleSectionSendCopyEvents && moduleId) {
      handleSectionSendCopyEvents(moduleId);
    }
  };

  const handleSendPaste = () => {
    console.log("Sending paste instructions to chrome extension...");
    window.parent.postMessage(
      {
        type: "pasteText",
        text: text,
      },
      "*"
    );
  };

  const handleSubmitCommentFeedback = (
    comment: string,
    ai_instruction_session_id: string
  ) => {
    setCommentSubmitted(true);
    setTimeout(() => {
      setHideCommentBox(true);
    }, 3000);

    // pass comment to parent component
    if (handleSectionCommentFeedback) {
      handleSectionCommentFeedback(comment, ai_instruction_session_id);
    }
  };

  const handleEditWithAISubmit = async (editInstructions: string) => {
    if (handleRequestAIEdit) {
      handleRequestAIEdit(
        editedText,
        editInstructions,
        moduleId ? moduleId : heading,
        (taskId: string) => {
          setAwaitingEditTaskId(taskId);
          setEditTaskResult(undefined);
        }
      );
    }
  };

  const checkEditTaskStatus = async (taskId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/notes/getEditSectionWithAITaskStatus?task_id=${taskId}`,
        accessToken: accessToken,
      });
      if (response.ok && response.value.status === 200) {
        if (pollingIntervalId.current) {
          clearInterval(pollingIntervalId.current);
        }
        setAwaitingEditTaskId(undefined); // Clear the taskId
        setEditTaskResult(response.value.task_result); // Store the result
      }
    } catch (error) {
      console.error("Failed to check task status:", error);
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    }
  };

  const getRegenerateSectionTaskStatus = async (taskId: string) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }
    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/notes/getSectionRegenerationTaskStatus?task_id=${taskId}`,
        accessToken: accessToken,
      });
      console.log(response);
      if (response.ok && response.value.status !== 202) {
        console.log(response.value.task_result);
        if (regenerationIntervalRef.current) {
          clearInterval(regenerationIntervalRef.current);
        }
        setAwaitingRegenerationTaskId(undefined); // Clear the taskId
        setEditedText(response.value.task_result.text_result);
        setLastSavedText(response.value.task_result.text_result);
        if (handleSaveEdits)
          handleSaveEdits(response.value.task_result.text_result);
        setIsRegenerating(false);
      }
    } catch (error) {
      console.error("Failed to check task status:", error);
      setIsRegenerating(false);
      if (regenerationIntervalRef.current) {
        clearInterval(regenerationIntervalRef.current);
      }
    }
  };

  const highlightTroubleWords = (text: string) => {
    if (!text) return text;

    if (typeof troubleWords !== "object" || troubleWords === null) {
      console.error("troubleWords is not a valid object:", troubleWords);
      return text;
    }

    const allMisspellings: string[] = troubleWords.reduce((acc, current) => {
      return [...acc, ...current.misspellings];
    }, [] as string[]);

    // Ensure misspellings are strings and log any non-string values
    allMisspellings.forEach((word, index) => {
      if (typeof word !== "string") {
        console.error(`Non-string value at index ${index}:`, word);
      }
    });

    // Helper function to escape special regex characters
    const escapeRegExp = (string: string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const misspellingsPattern = allMisspellings
      .filter((word) => typeof word === "string") // Filter out non-string values
      .sort((a, b) => b.length - a.length)
      .map(escapeRegExp)
      .join("|");

    const regex = new RegExp(
      `(?<=^|\\s|[.,!?;:()\\[\\]\\-/'"])(${misspellingsPattern})(?=\\s|[.,!?;:()\\[\\]\\-/'"]|$)`,
      "gi"
    );

    // Split the text into lines
    const lines = text.split("\n");

    // Process each line
    const processedLines = lines.map((line, lineIndex) => {
      const parts: (string | JSX.Element)[] = [];
      let lastIndex = 0;
      let match: RegExpExecArray | null;

      while ((match = regex.exec(line)) !== null) {
        const [, matchedWord] = match; // The actual match is in the first capturing group
        const matchIndex = match.index + (match[0].length - matchedWord.length); // Adjust for lookbehind

        if (matchIndex > lastIndex) {
          parts.push(line.slice(lastIndex, matchIndex));
        }

        parts.push(
          <span
            key={`${lineIndex}-${matchIndex}`}
            style={{ backgroundColor: "#FFE4E1", cursor: "pointer" }}
            onClick={() => {
              if (onWordClick) onWordClick(matchedWord);
            }}
          >
            {matchedWord}
          </span>
        );

        lastIndex = matchIndex + matchedWord.length;
      }

      if (lastIndex < line.length) {
        parts.push(line.slice(lastIndex));
      }

      // Wrap the processed line in a paragraph tag
      return (
        <p key={`line-${lineIndex}`} className="py-2">
          {parts}
        </p>
      );
    });

    return processedLines;
  };

  const handleDoubleClick = () => {
    if (!isEditMode) {
      toggleEditMode();
      setTimeout(() => {
        textAreaRef.current?.focus();
      }, 10);
    }
  };

  const handleAcceptAIEdits = () => {
    if (editTaskResult) {
      setEditedText(editTaskResult.result_text);
      setEditTaskResult(undefined);
      setEditInstructions("");
      setLastSavedText(editTaskResult.result_text);
      if (handleSaveEdits) handleSaveEdits(editTaskResult.result_text);

      // Use a timeout to allow the component to re-render before adjusting the textarea
      // setTimeout(() => {
      //   if (textAreaRef.current) {
      //     textAreaRef.current.style.height = 'auto';
      //     textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      //   }
      // }, 0);
    }
  };

  const handleRejectAIEdits = () => {
    setEditTaskResult(undefined);
    setEditInstructions("");

    // Use a timeout to allow the component to re-render before adjusting the textarea
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    }, 0);
  };

  const pollEditTaskStatusfromAIInstructions = (taskId: string) => {
    setAwaitingEditTaskId(taskId);
  };

  const handleThumbsDown = () => {
    if (markedRated === false) {
      setMarkedRated("0");
      handleSectionRatingFeedback && handleSectionRatingFeedback(0);
      setShowAIEditBox(true);
      setShowAIEditTrainingTooltip(true);
      setIsEditMode(true);
    }
  };

  const handleDoneEditing = () => {
    setIsEditMode(false);
    setShowAIEditBox(false);
    setShowAIEditTrainingTooltip(false);
  };

  useEffect(() => {
    if (awaitingEditTaskId) {
      pollingIntervalId.current = setInterval(() => {
        checkEditTaskStatus(awaitingEditTaskId);
      }, 3000) as unknown as number;
    }
    return () => {
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    };
  }, [awaitingEditTaskId]);

  useEffect(() => {
    if (awaitingRegenerationTaskId) {
      regenerationIntervalRef.current = setInterval(() => {
        getRegenerateSectionTaskStatus(awaitingRegenerationTaskId); // only line getting an error, cant find this func anywhere in the code base either. didnt make any changes here
      }, 3000) as unknown as number;
    }
    return () => {
      if (regenerationIntervalRef.current) {
        clearInterval(regenerationIntervalRef.current);
      }
    };
  }, [awaitingRegenerationTaskId]);

  useEffect(() => {
    // Add event listeners to manage focus within the editModeRef container
    const container = editModeRef.current;
    if (container) {
      container.addEventListener("focusout", handleBlur);
    }
    return () => {
      if (container) {
        container.removeEventListener("focusout", handleBlur);
      }
    };
  }, []);

  useEffect(() => {
    if (textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = "0px";

      let height = 0;

      if (isEditMode && noteTextRef.current) {
        height = noteTextRef.current.scrollHeight;
      } else {
        height = textAreaRef.current.scrollHeight;
      }

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = height + "px";
    }

    if (editedText.startsWith("N/A")) {
      setIsNA(true);
    } else {
      setIsNA(false);
    }
  }, [editedText, isEditMode]);

  useEffect(() => {
    if (componentIsMounted.current) {
      setIsLoading(text === "");
      setEditedText(text);
      setLastSavedText(text);
      setShowRevert(isEdited);
    }
  }, [text, isEdited]);

  useEffect(() => {
    if (isEditMode) {
      editModeRef.current?.focus();
    } else if (componentIsMounted.current) {
      // only run when isEditMode becomes false and not on the initial render
      editModeRef.current?.blur();

      // only save text to server if it has changed
      if (editedText !== lastSavedText) {
        setLastSavedText(editedText);
        if (handleSaveEdits) handleSaveEdits(editedText);
      }
    }
    componentIsMounted.current = true; // set this to true after the initial render
  }, [isEditMode]);

  return (
    <Box>
      <Box sx={{ marginBottom: 4, display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Box className="flex justify-between items-center">
            <Box className="text-xl pb-2 md:text-2xl">
              <Box
                id="note-section-heading"
                className="inline flex items-center"
              >
                {!isNA && !isMobile && (
                  <Box className="inline flex items-center">
                    {copied && (
                      <Box
                        id="tooltip-default"
                        role="tooltip"
                        sx={{
                          position: "absolute",
                          zIndex: "tooltip",
                          marginX: { md: "-5rem" },
                          paddingX: "0.75rem",
                          paddingY: "0.5rem",
                          fontSize: "0.875rem",
                          fontWeight: "600",
                          color: theme.palette.primary.light,
                          transition: "opacity 300ms",
                          bgcolor: "backgroundColors.secondary",
                          borderRadius: "4px",
                          boxShadow: 1,
                        }}
                      >
                        Copied
                      </Box>
                    )}

                    <ConditionalTooltip
                      title="This button selects a section to be copied when you press 'Copy Selected Sections' in the sidebar."
                      condition={showTooltips}
                      placement={"left"}
                      arrow
                    >
                      <>
                        {!isAdded && (
                          <Box
                            onClick={handleAdd}
                            sx={{ mx: 1, ":hover": { cursor: "pointer" } }}
                          >
                            <SVGIcon name="add" />
                          </Box>
                        )}
                        {isAdded && (
                          <Box
                            onClick={handleAdd}
                            sx={{ mx: 1, ":hover": { cursor: "pointer" } }}
                          >
                            <SVGIcon name="check-filled" />
                          </Box>
                        )}
                      </>
                    </ConditionalTooltip>

                    <ConditionalTooltip
                      title="Copy this section to your clipboard."
                      condition={showTooltips}
                      placement={"left"}
                      arrow
                    >
                      <Box
                        onClick={handleCopy}
                        sx={{ mx: 1, ":hover": { cursor: "pointer" } }}
                      >
                        <SVGIcon name="copy" />
                      </Box>
                    </ConditionalTooltip>
                  </Box>
                )}
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    pl: 2,
                    fontWeight: "bold",
                    color: isNA ? "textColors.disabled" : "textColors.darkText",
                    fontSize: { xs: "1.25rem", md: "1.5rem" },
                  }}
                >
                  {heading}
                </Typography>
              </Box>
            </Box>

            <Box className="flex items-center flex-wrap">
              {isEditable &&
                !isMobile &&
                sectionId &&
                handleSectionRatingFeedback &&
                !isRated &&
                !hideCommentBox && (
                  <ConditionalTooltip
                    title="These buttons give feedback on a section. This feedback is used to further improve your personal scribe."
                    condition={showTooltips}
                    placement={"left"}
                    arrow
                  >
                    <Box className="flex items-center mr-4">
                      <Box
                        onClick={handleThumbsDown}
                        sx={{ mx: 1, ":hover": { cursor: "pointer" } }}
                      >
                        {markedRated !== "0" && (
                          <ThumbDownIconAlt
                            htmlColor={theme.palette.primary.light}
                          />
                        )}
                        {markedRated && markedRated == "0" && (
                          <ThumbDownIconFilled
                            htmlColor={theme.palette.primary.light}
                          />
                        )}
                      </Box>
                      <Box
                        onClick={() => {
                          if (markedRated === false) {
                            setMarkedRated("1");
                            handleSectionRatingFeedback(1);
                          }
                        }}
                        sx={{ mx: 1, ":hover": { cursor: "pointer" } }}
                      >
                        {markedRated !== "1" && (
                          <ThumbUpIconAlt
                            htmlColor={theme.palette.primary.light}
                          />
                        )}
                        {markedRated && markedRated == "1" && (
                          <ThumbUpIconFilled
                            htmlColor={theme.palette.primary.light}
                          />
                        )}
                      </Box>
                    </Box>
                  </ConditionalTooltip>
                )}
              {isEditable &&
                !isMobile &&
                handleRevertEdits &&
                showRevert &&
                !isSaving &&
                !editTaskResult && (
                  <ConditionalTooltip
                    title="Discard your changes and revert the section to its original generation."
                    condition={showTooltips}
                    placement={"top"}
                    arrow
                  >
                    <RoundedButton
                      variant="contained"
                      onClick={() => {
                        showRevertModal({
                          isOpen: true,
                          title: "Revert Edits?",
                          children: (
                            <Typography>
                              Are you sure you want to revert the edits on this
                              section? This action cannot be undone.
                            </Typography>
                          ),
                          continueText: "Revert",
                          cancelText: "Cancel",
                          onCancel: () => hideRevertModal(),
                          onContinue: () => {
                            handleRevertEdits();
                            hideRevertModal();
                          },
                        });
                      }}
                    >
                      Revert Changes
                    </RoundedButton>
                  </ConditionalTooltip>
                )}
              {!isMobile && handleSaveEdits && !isEditMode && !isSaving && (
                <ConditionalTooltip
                  title={
                    isEditable
                      ? ""
                      : "This note is marked as complete. Press 'Review and Edit' below to make changes to this section."
                  }
                  condition={!isEditable}
                  placement="top"
                  arrow
                >
                  <span>
                    <RoundedButton
                      variant="outlined"
                      onClick={() => toggleEditMode()}
                      disabled={!isEditable}
                    >
                      Edit
                    </RoundedButton>
                  </span>
                </ConditionalTooltip>
              )}

              {handleSaveEdits && !isEditMode && isSaving && (
                <Box
                  className="max-w-sm"
                  sx={{ color: "textColors.lightText" }}
                >
                  Saving...
                </Box>
              )}
              {handleSaveEdits && isEditMode && !editTaskResult && (
                <RoundedButton
                  variant="contained"
                  onClick={() => handleDoneEditing()}
                >
                  Done
                </RoundedButton>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 0,
                }}
              >
                {loadedInChromeExtension && (
                  <Tooltip
                    title={
                      showChromeExtensionTooltips ? "Paste to your EHR" : ""
                    }
                  >
                    <Button
                      id="basic-button"
                      aria-controls={menuOpen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={menuOpen ? "true" : undefined}
                      onClick={handleSendPaste}
                    >
                      <ContentPasteGo />
                    </Button>
                  </Tooltip>
                )}
                {moduleId && (
                  <>
                    {isMobile && (
                      <Button
                        id="basic-button"
                        aria-controls={menuOpen ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? "true" : undefined}
                        onClick={handleMenuClick}
                      >
                        <MoreHoriz />
                      </Button>
                    )}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {isEditable &&
                        isMobile &&
                        sectionId &&
                        handleSectionRatingFeedback &&
                        !isRated &&
                        !hideCommentBox && (
                          <MenuItem onClick={handleMenuClose}>
                            <Box className="flex items-center">
                              <Button
                                onClick={() => {
                                  if (markedRated === false) {
                                    setMarkedRated("0");
                                    handleSectionRatingFeedback(0);
                                  }
                                }}
                                className="px-1"
                              >
                                {markedRated !== "0" && (
                                  <ThumbDownIconAlt
                                    htmlColor={theme.palette.primary.light}
                                  />
                                )}
                                {markedRated && markedRated == "0" && (
                                  <ThumbDownIconFilled
                                    htmlColor={theme.palette.primary.light}
                                  />
                                )}
                              </Button>
                              <Button
                                onClick={() => {
                                  if (markedRated === false) {
                                    setMarkedRated("1");
                                    handleSectionRatingFeedback(1);
                                  }
                                }}
                                className="px-1"
                              >
                                {markedRated !== "1" && (
                                  <ThumbUpIconAlt
                                    htmlColor={theme.palette.primary.light}
                                  />
                                )}
                                {markedRated && markedRated == "1" && (
                                  <ThumbUpIconFilled
                                    htmlColor={theme.palette.primary.light}
                                  />
                                )}
                              </Button>
                            </Box>
                          </MenuItem>
                        )}
                      {isMobile && !isEditMode && !isSaving && (
                        <MenuItem
                          onClick={() => {
                            handleMenuClose();
                            handleCopy();
                          }}
                        >
                          Copy Section
                        </MenuItem>
                      )}
                      {isEditable &&
                        isMobile &&
                        handleRevertEdits &&
                        showRevert &&
                        !isSaving &&
                        !editTaskResult && (
                          <MenuItem
                            onClick={() => {
                              handleMenuClose();
                              // Show the revert modal
                              showRevertModal({
                                isOpen: true,
                                title: "Revert Edits?",
                                children: (
                                  <Typography>
                                    Are you sure you want to revert the edits on
                                    this section? This action cannot be undone.
                                  </Typography>
                                ),
                                continueText: "Revert",
                                cancelText: "Cancel",
                                onCancel: () => hideRevertModal(),
                                onContinue: () => {
                                  handleRevertEdits();
                                  hideRevertModal();
                                },
                              });
                            }}
                          >
                            Revert Changes
                          </MenuItem>
                        )}
                      {isEditable &&
                        isMobile &&
                        handleSaveEdits &&
                        !isEditMode &&
                        !isSaving && (
                          <MenuItem
                            onClick={() => {
                              handleMenuClose();
                              setIsEditMode(true);
                            }}
                          >
                            Edit
                          </MenuItem>
                        )}
                    </Menu>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {(isEditMode || showAIEditBox) && (
            <ConditionalTooltip
              title="Train your scribe by providing specific feedback on how to improve this section."
              condition={showAIEditBox}
              placement="bottom"
              arrow
              open={showAIEditTrainingTooltip}
            >
              <Box
                sx={{ mt: 1 }}
                onFocus={() => setShowAIEditTrainingTooltip(false)}
              >
                <EditWithAI
                  editInstructions={editInstructions}
                  setEditInstructions={setEditInstructions}
                  onEditInstructionsSubmit={handleEditWithAISubmit}
                  loading={awaitingEditTaskId ? true : false}
                  handleStartModuleCustomizationSession={
                    handleStartModuleCustomizationSession
                  }
                  sectionContent={editedText}
                  moduleId={moduleId || ""}
                  pollEditTaskStatusfromAIInstructions={
                    pollEditTaskStatusfromAIInstructions
                  }
                  handleSubmitCommentFeedback={
                    showAIEditBox ? handleSubmitCommentFeedback : undefined
                  }
                />
              </Box>
            </ConditionalTooltip>
          )}

          {/* {markedRated && !isRated && (
            <Box className="p-1">
              {!commentSubmitted && (
                <InlineCommentBox onSubmit={handleSumbitCommentFeedback} />
              )}
              {commentSubmitted && !hideCommentBox && (
                <Typography color="textColors.primaryText">
                  Thank you for your feedback!
                </Typography>
              )}
            </Box>
          )} */}
          <Box
            className="flex-auto mt-2"
            ref={editModeRef}
            onDoubleClick={isEditable ? handleDoubleClick : undefined}
          >
            {isErrored && (
              <Box className="p-4 border-1 border-gray-200 border-dashed rounded-lg  w-full resize-none overflow-hidden leading-7 text-center">
                <Typography color="textColors.primaryText">
                  Error generating section.
                </Typography>
                {handleRegenerateSection && (
                  <RoundedButton
                    onClick={() => {
                      setIsRegenerating(true);
                      handleRegenerateSection(
                        moduleId ? moduleId : heading,
                        (taskId: string) => {
                          setAwaitingRegenerationTaskId(taskId);
                        }
                      );
                    }}
                  >
                    {isRegenerating ? "Regenerating..." : "Regenerate"}
                  </RoundedButton>
                )}
              </Box>
            )}
            {!isEditMode && !isErrored && (
              <ConditionalTooltip
                title={
                  isEditable
                    ? "This is the generated text for this note section. You can double click or press the 'edit' button to make changes."
                    : ""
                }
                condition={showTooltips && isEditable}
                placement={"left"}
                arrow
              >
                <Box
                  sx={{
                    p: 4,
                    border: 1,
                    borderRadius: "0.75rem",
                    color: isNA ? "textColors.disabled" : "textColors.darkText",
                    borderColor: "borderColors.primary",
                    bgcolor: "backgroundColors.primary",
                  }}
                >
                  {isLoading && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  {!isLoading && highlightTroubleWords(editedText)}
                </Box>
              </ConditionalTooltip>
            )}
            {isEditable && isEditMode && (
              <>
                {editTaskResult && (
                  <>
                    <Box
                      ref={noteTextRef}
                      // onDoubleClick={handleSaveEdits ? toggleEditMode : () => {}}
                      sx={{
                        p: 4,
                        mb: 1,
                        border: 2,
                        borderStyle: "dashed",
                        borderRadius: "0.75rem",
                        color: isNA
                          ? "textColors.disabled"
                          : "textColors.darkText",
                        borderColor: isNA
                          ? "borderColors.lightprimary"
                          : "borderColors.primary",
                        bgcolor: isNA
                          ? "backgroundColors.primary"
                          : Colors.grey100,
                      }}
                    >
                      {/* {editTaskResult.diffs.map((diff: any, index: number) =>
                        renderDiff(diff)
                      )} */}
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <RoundedButton
                          variant="contained"
                          onClick={handleAcceptAIEdits}
                        >
                          Accept Changes
                        </RoundedButton>

                        <RoundedButton
                          variant="contained"
                          onClick={handleRejectAIEdits}
                        >
                          Reject Changes
                        </RoundedButton>
                      </Box>
                      {renderDiffs(editTaskResult.diffs)}
                    </Box>
                  </>
                )}
                {!editTaskResult && (
                  <textarea
                    ref={textAreaRef}
                    className="p-4 md:p-8 border-1 rounded-lg w-full resize-none overflow-hidden leading-7 md:leading-8"
                    style={{
                      ...{
                        color: isNA ? Colors.grey500 : Colors.grey700,
                      },
                      ...{
                        backgroundColor: isNA
                          ? Colors.primary100
                          : Colors.grey100,
                      },
                      ...{ display: isEditMode ? "initial" : "none" },
                      ...{ borderColor: Colors.primary200 },
                    }}
                    value={editedText}
                    onChange={handleTextChange}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {debug && (
        <Box>
          <RoundedButton
            variant="outlined"
            sx={{ color: "textColors.primaryText" }}
            className="ml-20 font-medium text-sm px-5 py-2.5 mr-2 mb-2 text-center"
            onClick={() => {
              setShowContext(!showContext);
            }}
          >
            Show Context
          </RoundedButton>
          {handleRegenerateSection && (
            <RoundedButton
              variant="outlined"
              sx={{ color: "textColors.primaryText" }}
              className="ml-20 font-medium text-sm px-5 py-2.5 mr-2 mb-2 text-center"
              onClick={() => {
                setIsRegenerating(true);
                handleRegenerateSection(
                  moduleId ? moduleId : heading,
                  (taskId: string) => {
                    setAwaitingRegenerationTaskId(taskId);
                  }
                );
              }}
            >
              {isRegenerating
                ? "Regenerating..."
                : "Regenerate (CAREFUL: PERMANENT)"}
            </RoundedButton>
          )}
          {edits && (
            <RoundedButton
              variant="outlined"
              sx={{ color: "textColors.primaryText" }}
              className="ml-20 font-medium text-sm px-5 py-2.5 mr-2 mb-2 text-center"
              onClick={() => {
                setShowEdits(!showEdits);
              }}
            >
              Show Edits
            </RoundedButton>
          )}
          {/* debug print of context sections */}
          {showContext && (
            <Box className="my-4 px-20">
              {isContextEditMode ? (
                <textarea
                  className="p-4 border-1 rounded-lg w-full leading-7"
                  style={{ borderColor: Colors.primary200 }}
                  value={editedContext}
                  onChange={handleContextChange}
                  onBlur={() => setIsContextEditMode(false)}
                />
              ) : (
                <Box
                  className="p-4 border-1 rounded-lg"
                  onDoubleClick={toggleContextEditMode}
                  style={{
                    backgroundColor: Colors.primary100,
                    color: Colors.grey700,
                  }}
                >
                  {editedContext?.split("\n").map((el) => {
                    if (el) {
                      return <p key={el}>{el}</p>;
                    }
                    return null;
                  })}
                </Box>
              )}
            </Box>
          )}
          {edits && showEdits && (
            <Box className="my-4 px-20">
              {edits
                .slice()
                .reverse()
                .map((edit: any) => (
                  <EditDiffComponent
                    diffs={edit.diff}
                    timestamp={edit.timestamp}
                  />
                ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
