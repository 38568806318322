import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Box,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { AvailableModule } from "../../../types/types";
import APIService from "../../../services/APIService";
import { Visibility } from "@mui/icons-material";

interface ModulePickerProps {
  onModuleSelect: (module_id: string) => void;
}

const ModulePicker: React.FC<ModulePickerProps> = ({ onModuleSelect }) => {
  const [availableModules, setAvailableModules] = useState<AvailableModule[]>([]);
  const [selectedModuleId, setSelectedModuleId] = useState<string>();

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchModules = async (search: string = "") => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    const response = await APIService.makeAPIGetRequest({
      requestString: `/template/getAllModules?search=${search}`,
      accessToken: accessToken,
    });

    if (response.ok) {
      setAvailableModules(response.value.modules);
    }
  };

  const handleModuleChange = (event: any, value: AvailableModule | null) => {
    if (value) {
      setSelectedModuleId(value.module_id);
      onModuleSelect(value.module_id);
    } else {
      setSelectedModuleId("");
      onModuleSelect("");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <FormControl fullWidth>
        <Autocomplete
          options={availableModules}
          getOptionLabel={(option) =>
            option.name ? option.name : option.module_name
          }
          renderInput={(params) => (
            <TextField {...params} label="Choose a module" variant="outlined" />
          )}
          onChange={handleModuleChange}
        />
      </FormControl>
      {selectedModuleId && (
        <IconButton
          aria-label="preview"
          onClick={() => {
            window.open(
              `${window.location.origin}/admin/module/${selectedModuleId}`,
              "_blank"
            );
          }}
        >
          <Visibility />
        </IconButton>
      )}
    </Box>
  );
};

export default ModulePicker;
