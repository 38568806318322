import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Snackbar, Alert } from '@mui/material';
import { Person, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { DeleteModal } from "./DeleteModal";

interface PatientUnassignmentProps {
  patientData: {
    patient_id: string;
    first_name: string;
    last_name: string;
  };
  noteId: string;
  onUnassignSuccess: () => void;
}

const PatientUnassignment: React.FC<PatientUnassignmentProps> = ({ patientData, noteId, onUnassignSuccess }) => {
  const { getAccessToken } = useUser();
  const [showUnassignModal, setShowUnassignModal] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  // Add this useEffect to log snackbar state changes
  useEffect(() => {
    console.log("Snackbar state changed:", snackbar);
  }, [snackbar]);

  const handleUnassignPatient = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: `/patient/unassignpatient`,
        accessToken,
        body: {
          note_id: noteId,
        },
      });

      if (response.ok) {
        setSnackbar({
            open: true,
            message: "Patient unassigned successfully",
            severity: "success",
          });
        console.log("Setting success snackbar");
        setTimeout(() => {
          onUnassignSuccess();
        }, 1000);       
        setShowUnassignModal(false);
      } else {
        throw new Error("Failed to unassign patient");
      }
    } catch (error) {
      console.error("Error unassigning patient:", error);
      console.log("Setting error snackbar");
      setSnackbar({
        open: true,
        message: "Failed to unassign patient",
        severity: "error",
      });
    } finally {
      setShowUnassignModal(false);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          size="small"
          onClick={() => setShowUnassignModal(true)}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          sx={{
            mr: 1,
            color: "textColors.secondaryText",
          }}
        >
          {isHovering ? <CloseIcon /> : <Person />}
        </IconButton>
        <Typography
          variant="subtitle1"
          sx={{
            color: "textColors.secondaryText",
            fontSize: {
              xs: "1rem",
              sm: "1.1rem",
              md: "1.2rem",
            },
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => navigate(`/patient/${patientData.patient_id}`)}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          
        >
          {`${patientData.first_name} ${patientData.last_name}`}
        </Typography>
      </Box>
          
      <DeleteModal
        isOpen={showUnassignModal}
        onCancel={() => setShowUnassignModal(false)}
        onContinue={handleUnassignPatient}
        continueText="Unassign"
        cancelText="Cancel"
      >
        Are you sure you want to unassign this patient from the note? This action cannot be undone.
      </DeleteModal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
    >
        <Alert
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
        >
        {snackbar.message}
        </Alert>
    </Snackbar>
    </>
  );
};

export default PatientUnassignment;