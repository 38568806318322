import React, { ReactNode } from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { DismissableCard, DismissableCardProps } from "./DismissableCard";

interface SlideSidePaneProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  dismissableCards?: DismissableCardProps[];
}

const SlideSidePane: React.FC<SlideSidePaneProps> = ({
  open,
  onClose,
  title,
  children,
  dismissableCards,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDismissableCardClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="slide-side-pane-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Box sx={{ height: "100%", width: "100%", display: "flex" }}>
          <PanelGroup direction="horizontal">
            <Panel minSize={20} onClick={onClose}>
              <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
                {dismissableCards && (
                  <Box
                    onClick={handleDismissableCardClick}
                    sx={{
                      position: "absolute",
                      right: 16,
                      top: 16,
                      width: 300,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    {dismissableCards.map((cardProps, index) => (
                      <DismissableCard key={index} {...cardProps} />
                    ))}
                  </Box>
                )}
              </Box>
            </Panel>
            <PanelResizeHandle>
              <Box
                sx={{
                  width: 5,
                  height: "100%",
                  bgcolor: "divider",
                  cursor: "ew-resize",
                }}
              />
            </PanelResizeHandle>
            <Panel defaultSize={40} minSize={20}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <IconButton
                    onClick={onClose}
                    aria-label="close"
                    sx={{ mr: 1 }}
                  >
                    <DoubleArrowIcon />
                  </IconButton>
                  <Typography
                    variant="h6"
                    component="h2"
                    id="slide-side-pane-title"
                    sx={{ flexGrow: 1 }}
                  >
                    {title}
                  </Typography>
                  <IconButton onClick={onClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Box>
                <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
                  {children}
                </CardContent>
              </Card>
            </Panel>
          </PanelGroup>
        </Box>
      </Slide>
    </Modal>
  );
};

export default SlideSidePane;
