import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface PatientInformationViewProps {
  patientId: string;
  highlightedFields?: string[];
  onPatientSave?: (updatedPatient: Patient) => void;
}

const PatientInformationView: React.FC<PatientInformationViewProps> = ({
  patientId,
  highlightedFields = [],
  onPatientSave,
}) => {
  const [patient, setPatient] = useState<Patient | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const { getAccessToken } = useUser();

  useEffect(() => {
    fetchPatient();
  }, [patientId]);

  const fetchPatient = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/get?patient_id=${patientId}&origin=PatientInformationView`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setPatient(data.patient);
      } else {
        throw new Error("Failed to fetch patient");
      }
    } catch (error) {
      console.error("Error fetching patient:", error);
      setSnackbar({
        open: true,
        message: "Failed to load patient data",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatient((prev) => {
      if (!prev) return null;
      const updatedPatient = { ...prev, [name]: value };
      setHasChanges(true);
      return updatedPatient;
    });
  };

  const handleSave = async () => {
    if (!patient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/update",
        accessToken,
        body: {
          patient_id: patientId,
          updated_fields: patient,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Patient updated successfully",
          severity: "success",
        });
        setHasChanges(false);
        if (onPatientSave) onPatientSave(patient);
      } else {
        throw new Error("Failed to update patient");
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      setSnackbar({
        open: true,
        message: "Failed to update patient",
        severity: "error",
      });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!patient) {
    return <Typography>No patient data available.</Typography>;
  }

  const fields = [
    { name: "first_name", label: "First Name" },
    { name: "middle_name", label: "Middle Name" },
    { name: "last_name", label: "Last Name" },
    { name: "identifier", label: "Identifier (PRN)" },
    { name: "dob", label: "Date of Birth", type: "date" },
    { name: "gender", label: "Gender" },
    { name: "race", label: "Race" },
    { name: "address", label: "Address" },
    { name: "phone", label: "Phone Number" },
    { name: "email", label: "Email" },
  ];

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Patient Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {fields.map((field) => (
          <TextField
            key={field.name}
            label={field.label}
            name={field.name}
            value={patient[field.name as keyof Patient] || ""}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            type={field.type || "text"}
            InputLabelProps={
              field.type === "date" ? { shrink: true } : undefined
            }
            inputProps={
              field.type === "date"
                ? { max: new Date().toISOString().split("T")[0] }
                : undefined
            }
            sx={{
              flexGrow: 1,
              minWidth: "150px",
              ...(highlightedFields.includes(field.name) && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "primary.main",
                    borderWidth: 2,
                  },
                },
              }),
            }}
          />
        ))}
      </Box>
      {hasChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default PatientInformationView;
